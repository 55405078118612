<template>
  <div class="container-fluid">
    <loading :active.sync="isLoading" loader="dots"></loading>

    <div class="function-card">
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#createModal"
        @click="openCreateModal('main')"
      >
        {{ i18n("Buttons.Add") }}
      </button>
    </div>

    <div class="yahome-table-wrap">
      <VueTreeGrid
        :key="treeKey"
        :columns="columns"
        :tree="tree"
        @expand="onRowExpanded"
      >
        <template slot="sort--row-cell" slot-scope="scope">
          <div class="text-center">
            <button
              v-if="scope.row._index !== 0"
              class="btn btn btn-outline-secondary btn-sm"
              @click="changeMenuSort(scope.row, 'up')"
            >
              <i class="fas fa-long-arrow-alt-up"></i>
            </button>

            <span v-if="scope.row._level === 1">
              <button
                v-if="scope.row._index !== tree.length - 1"
                class="btn btn btn-outline-secondary btn-sm"
                @click="changeMenuSort(scope.row, 'down')"
              >
                <i class="fas fa-long-arrow-alt-down"></i>
              </button>
            </span>
            <span v-if="scope.row._level === 2">
              <button
                v-if="scope.row._index !== scope.row.childrenLength - 1"
                class="btn btn btn-outline-secondary btn-sm"
                @click="changeMenuSort(scope.row, 'down')"
              >
                <i class="fas fa-long-arrow-alt-down"></i>
              </button>
            </span>
          </div>
        </template>
        <template slot="action--row-cell" slot-scope="scope">
          <button
            v-if="scope.row._level === 1"
            type="button"
            class="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#createModal"
            @click="openCreateModal('child', scope.row)"
          >
            {{ i18n("Buttons.Add") }}
          </button>
          <button
            type="button"
            class="btn btn-info btn-sm"
            data-toggle="modal"
            data-target="#editModal"
            @click="openEditModal(scope.row)"
          >
            {{ i18n("Buttons.Edit") }}
          </button>
          <button
            v-if="scope.row.ExternalLink && scope.row._childrenLength === 0"
            type="button"
            class="btn btn-danger btn-sm"
            data-toggle="modal"
            data-target="#delMenu"
            @click="openDelModal(scope.row)"
          >
            {{ i18n("Buttons.Delete") }}
          </button>
        </template>
      </VueTreeGrid>
    </div>

    <!--新增 Modal-->
    <div
      id="createModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="createMenu"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="!ParentName">
                {{ i18n("Menu.AddMain") }}
              </span>
              <span v-else>
                <span class="badge badge-primary" style="font-size: 15px">
                  {{ ParentName }}
                </span>
                {{ i18n("Menu.AddSub") }}
              </span>
            </div>
            <div class="modal-body" style="height: 400px">
              <div v-if="!ParentName" class="form-group">
                <ValidationProvider
                  name="icon"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>icon</label>
                  <multiselect
                    v-model="Icon"
                    placeholder="請選擇"
                    label="title"
                    track-by="title"
                    :options="IconList"
                    :show-labels="false"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <img
                        style="height: 25px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                    <template slot="option" slot-scope="props">
                      <img
                        style="height: 30px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                  </multiselect>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Menu.MenuName')"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>{{ i18n("Menu.MenuName")}}<span class="mark">(不可重複)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="createTemp.MenuName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <div v-if="ParentName" class="form-group">
                <ValidationProvider
                  :name="i18n('Menu.MenuLink')"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>{{ i18n("Menu.MenuLink") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="createTemp.Link"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div v-if="ParentName" class="form-group">
                <label>
                  <span class="mark">*</span>{{ i18n("Menu.OpenAnotherWindow") }}
                </label>
                <select class="form-control" v-model="createTemp.NewWindow">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>

              <div v-if="ParentName" class="form-group">
                <label>
                  <span class="mark">*</span>{{ i18n("Menu.ExternalLink") }}
                </label>
                <select class="form-control" v-model="createTemp.ExternalLink">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(createMenu)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--編輯 Modal-->
    <div
      id="editModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="editMenu"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form @reset.prevent="reset">
            <div class="modal-header">
              <span v-if="!ParentName"
                >{{ i18n("Menu.EditMain") }}</span
              >
              <span v-else
                >{{ i18n("Menu.EditSub") }}</span
              >
            </div>
            <div class="modal-body" style="height: 400px">
              <div v-if="!ParentName" class="form-group">
                <ValidationProvider
                  name="icon"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>icon</label>
                  <multiselect
                    v-model="Icon"
                    placeholder="請選擇"
                    label="title"
                    track-by="title"
                    :options="IconList"
                    :show-labels="false"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <img
                        style="height: 25px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                    <template slot="option" slot-scope="props">
                      <img
                        style="height: 30px"
                        :src="props.option.img"
                        alt="icon"
                      />
                    </template>
                  </multiselect>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  :name="i18n('Menu.MenuName')"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label><span class="mark">*</span>{{ i18n("Menu.MenuName") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    @compositionstart="is_composing = true"
                    @compositionend="is_composing = false"
                    v-model="editTemp.MenuName"
                  />
                  <span v-if="errors[0] && !is_composing" class="text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div v-if="canEdit" class="form-group">
                <ValidationProvider
                  :name="i18n('Menu.MenuLink')"
                  rules="required"
                  tag="div"
                  v-slot="{ errors }"
                >
                  <label>
                    <span class="mark">*</span>
                    {{ i18n("Menu.MenuLink") }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="editTemp.Link"
                  />
                  <span class="text-danger">{{ errors[0] }} </span>
                </ValidationProvider>
              </div>

              <div v-if="ParentName" class="form-group">
                <label>{{ i18n("Menu.OpenAnotherWindow") }}</label>
                <select class="form-control" v-model="editTemp.NewWindow">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>

              <div v-if="canEdit" class="form-group">
                <label><span class="mark">*</span>{{ i18n("Menu.ExternalLink") }}</label>
                <select class="form-control" v-model="editTemp.ExternalLink">
                  <option :value="true">是</option>
                  <option :value="false">否</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {{ i18n("Buttons.Cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                @click="handleSubmit(editMenu)"
              >
                {{ i18n("Buttons.Save") }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- 刪除選單 Modal -->
    <div
      id="delMenu"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ i18n("Menu.DeleteMenu") }}</div>
          <div class="modal-body">
            確認刪除
            <span style="font-weight: bolder; color: red">
              {{ delelteTemp.MenuName }}</span
            >
            ?
          </div>
          <div class="modal-footer">
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Buttons.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="delMenu(delelteTemp)"
            >
              {{ i18n("Buttons.Delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import VueTreeGrid from "@reallife/vue-tree-grid";
import { mapGetters } from "vuex";

export default {
  name: "Menu",

  components: {
    VueTreeGrid,
  },
  data() {
    return {
      treeLength: 0,
      is_composing: false, // compositionstart& compositionend

      // icon
      Icon: {},
      // 選單icon 提供
      IconList: [
        {
          title: "settings-outline",
          img: require("@/assets/icons/eva/settings-outline.png"),
        },
        {
          title: "options-2-outline",
          img: require("@/assets/icons/eva/options-2-outline.png"),
        },
        {
          title: "file-text-outline",
          img: require("@/assets/icons/eva/file-text-outline.png"),
        },
        {
          title: "search-outline",
          img: require("@/assets/icons/eva/search-outline.png"),
        },
        {
          title: "person-outline",
          img: require("@/assets/icons/eva/person-outline.png"),
        },
        {
          title: "person-add-outline",
          img: require("@/assets/icons/eva/person-add-outline.png"),
        },
        {
          title: "people-outline",
          img: require("@/assets/icons/eva/people-outline.png"),
        },
        {
          title: "pie-chart-outline",
          img: require("@/assets/icons/eva/pie-chart-outline.png"),
        },
        {
          title: "pantone-outline",
          img: require("@/assets/icons/eva/pantone-outline.png"),
        },
        {
          title: "link-2-outline",
          img: require("@/assets/icons/eva/link-2-outline.png"),
        },
        {
          title: "clipboard-outline",
          img: require("@/assets/icons/eva/clipboard-outline.png"),
        },
        {
          title: "share-outline",
          img: require("@/assets/icons/eva/share-outline.png"),
        },
        {
          title: "image-outline",
          img: require("@/assets/icons/eva/image-outline.png"),
        },
        {
          title: "email-outline",
          img: require("@/assets/icons/eva/email-outline.png"),
        },
        {
          title: "inbox-outline",
          img: require("@/assets/icons/eva/inbox-outline.png"),
        },
        {
          title: "calendar-outline",
          img: require("@/assets/icons/eva/calendar-outline.png"),
        },
        {
          title: "folder-outline",
          img: require("@/assets/icons/eva/folder-outline.png"),
        },
        {
          title: "message-square-outline",
          img: require("@/assets/icons/eva/message-square-outline.png"),
        },
        {
          title: "eye-outline",
          img: require("@/assets/icons/eva/eye-outline.png"),
        },
        {
          title: "printer-outline",
          img: require("@/assets/icons/eva/printer-outline.png"),
        },
        {
          title: "cloud-download-outline",
          img: require("@/assets/icons/eva/cloud-download-outline.png"),
        },
        {
          title: "facebook-outline",
          img: require("@/assets/icons/eva/facebook-outline.png"),
        },
      ],

      ParentName: null,

      createTemp: {},

      editTemp: {},
      canEdit: false, // 能否編輯

      delelteTemp: {},

      treeKey: 0,
      tree: [],
      treePath: null,

      columns: [
        {
          type: "prop",
          prop: "sort",
          width: "5%"
        },
        { 
          label: this.i18n("Menu.MenuName"),
          prop: "MenuName",
          width: "25%"
        },
        { 
          label: this.i18n("Menu.MenuLink"),
          prop: "Link",
          width: "25%"
        },
        {
          type: "prop",
          prop: "action",
          label: "",
          width: "5%"
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["lang"]),
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  watch: {
    lang: function() {
      this.columns = [
        { type: "prop", prop: "sort", width: "5%" },
        { label: this.i18n("Menu.MenuName"), prop: "MenuName", width: "25%" },
        { label: this.i18n("Menu.MenuLink"), prop: "Link", width: "25%" },
        { type: "prop", prop: "action", label: "", width: "5%" },
      ]
    },
  },

  methods: {
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    // 延續展開
    onRowExpanded(row) {
      if (this.treePath === Number(row._path)) {
        this.treePath = null;
      } else {
        this.treePath = Number(row._path);
      }
    },

    // 取得選單 API
    getMenu() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu`;
      vm.$store.dispatch("updateLoading", true);
      let tree = [];
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.treeKey++;
            response.data.forEach((item) => {
              let data = {
                ...item,
                children: item.Children,
                _isExpanded: false,
              };
              data.children.forEach((item) => {
                item.childrenLength = data.children.length;
              });
              tree.push(data);
            });
            vm.tree = tree;
            if (vm.treePath !== null) {
              vm.tree[vm.treePath]._isExpanded = true;
            }
            vm.$store.commit("EDITMENU", false);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 改變順序 API
    changeMenuSort(item, type) {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu/sort`;

      const data = {
        MenuGuid: item.Guid,
        Turn: type,
      };

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "順序調整成功",
              type: "success",
              duration: 2000,
            });
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 新增選單 Modal
    openCreateModal(type, item) {
      this.ParentName = null;
      this.createTemp = {};
      this.Icon = null;
      if (type === "child") {
        this.ParentName = item.MenuName;
        this.createTemp.ParentMenuGuid = item.Guid;
        this.$set(this.createTemp, "NewWindow", false);
        this.$set(this.createTemp, "ExternalLink", true);
      } else {
        this.createTemp.ParentMenuGuid = null;
        this.$set(this.createTemp, "Link", "--");
        this.$set(this.createTemp, "NewWindow", false);
        this.$set(this.createTemp, "ExternalLink", false);
      }
      this.$refs.createMenu.reset();
    },

    // 新增選單 API
    createMenu() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu`;

      let message = "子選單新增成功";
      let icon = null;

      if (vm.createTemp.ParentMenuGuid === null) {
        icon = vm.Icon.title;
        message = "主選單新增成功";
      }

      const data = {
        ...this.createTemp,
        Icon: icon,
        CreateUser: vm.$cookies.get("userName"),
      };

      $("#createModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: message,
              type: "success",
              duration: 2000,
            });
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 編輯選單 Modal
    openEditModal(item) {
      this.ParentName = null; // 上層選單名稱
      this.canEdit = false; // 預設不可編輯

      if (item.ParentMenuGuid) {
        if (item.Link.includes("http")) {
          this.canEdit = true;
        }
        this.ParentName = item.ParentMenuGuid;
      } else {
        this.Icon = {
          title: item.Icon,
          img: require(`@/assets/icons/eva/${item.Icon}.png`),
        };
      }

      this.editTemp = { ...item };
      this.$refs.editMenu.reset();
    },

    // 編輯選單 API
    editMenu() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu`;

      let icon = null;
      if (vm.editTemp.ParentMenuGuid === null) {
        icon = vm.Icon.title;
      }

      const data = {
        ...this.editTemp,
        Icon: icon,
        CreateUser: vm.$cookies.get("userName"),
      };

      $("#editModal").modal("hide");
      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .put(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.commit("EDITMENU", true);
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "編輯成功",
              type: "success",
              duration: 2000,
            });
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },

    // 刪除選單 Modal
    openDelModal(item) {
      this.delelteTemp = { ...item };
    },

    // 刪除選單 API
    delMenu(item) {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/menu/${item.Guid}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .delete(api)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "刪除成功",
              type: "success",
              duration: 2000,
            });
            vm.$store.commit("EDITMENU", true);
            vm.getMenu();
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "失敗",
            message: error.response.data,
            type: "error",
            duration: 2000,
          });
        });
    },
  },

  created() {
    this.getMenu();
  },

  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>

<style lang="scss">
i.tree-grid__expand-icon {
  background-color: #4062c2 !important;
  border-radius: 50% !important;
  color: #fff;
  line-height: 17px !important;
  width: 20px !important;
  height: 20px !important;
  margin-left: -20px;

  &.tree-grid__expand-icon--expanded {
    background-color: #e55353 !important;
  }
}

.tree-grid__body-cell {
  .btn {
    margin-right: 5px;
  }
}
</style>
